<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      systeminfo: [],
      newuserinfo: {
        password: "",
        repassword: "",
      },
      showsuccess: false,
      form: {
        oldPassword: 'admin',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        newPassword: [
          { required: true, message: this.$t('home.password.newpassword_tip'), trigger: 'blur' },
          { min: 6, message: this.$t('home.password.newpassword_length'), trigger: 'blur' },
          { validator: this.validateNewPassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: this.$t('home.password.newpassword_check_tip'), trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
      },
      otp_status: false,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.setting.account");
    this.items = [
      {
        text: this.$t("menuitems.setting.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.setting.account"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getotpstatus();
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 执行密码修改逻辑
          if (this.otp_status) {
            this.$prompt(
              this.$t("setting.system.otpbind_input"),
              this.$t("setting.system.otpbind_confirm"),
              {
                confirmButtonText: this.$t("global.button.ok"),
                cancelButtonText: this.$t("global.button.cancel"),
                inputPattern: /^[0-9]{6}$/,
                inputErrorMessage: this.$t("setting.system.otpbind_input_error"),
              }
            ).then(({ value }) => {
              this.$axios.post(this.apiuri, {
                action: "checkotptoken_online",
                code: value
              },
                {
                  headers: { Openid: this.userinfo.openid },
                }
              ).then((response) => {
                if (response.data.status === 200) {
                  this.sendPasswordChange();
                } else {
                  this.$message({
                    message: this.$t("setting.system.otpcheck_failed"),
                    type: "error",
                  });
                }
              }).catch((error) => {
                console.error("绑定密钥失败:", error);
                this.$message({
                  message: this.$t("setting.system.otpbind_error"),
                  type: "error",
                });
              });
            }).catch(() => { });
          } else {
            this.sendPasswordChange();
          }

        } else {
          this.$message.error($t('global.form.validate_failed'));
          return false;
        }
      });
    },
    validateNewPassword(rule, value, callback) {
      const lengthValid = value.length > 6;
      const hasLetter = /[a-zA-Z]/.test(value);
      const hasDigit = /\d/.test(value);
      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);

      const typesCount = [hasLetter, hasDigit, hasSymbol, hasUpperCase].filter(Boolean).length;

      if (!lengthValid) {
        callback(new Error(this.$t('home.password.newpassword_length')));
      } else if (typesCount < 3) {
        callback(new Error(this.$t('home.password.newpassword_type')));
      } else {
        callback();
      }
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.form.newPassword) {
        callback(new Error(this.$t('home.password.newpassword_double')));
      } else {
        callback();
      }
    },
    sendPasswordChange() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changeadminpwd",
            password: that.form.confirmPassword,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.gettodolist();
          if (response.data.status == 200) {
            that.showsuccess = true;
            that.$message.success(that.$t('home.password.success'));
          } else {
            that.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getotpstatus() {
      this.$axios
        .post(this.apiuri, { action: 'getotpstatus' })
        .then((response) => {
          if (response.data.status === 200) {
            this.otp_status = true;
          }
        })
        .catch((error) => {
          console.error('获取 OTP 状态失败:', error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="!showsuccess">
            <el-form :model="form" :rules="rules" ref="form">
              <el-form-item :label="$t('home.password.newpassword')" prop="newPassword">
                <el-input v-model="form.newPassword" :placeholder="$t('home.password.newpassword_tip')"
                  show-password></el-input>
              </el-form-item>
              <el-form-item :label="$t('home.password.newpassword_check')" prop="confirmPassword">
                <el-input v-model="form.confirmPassword" :placeholder="$t('home.password.newpassword_check_tip')"
                  show-password></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="submitForm">{{ $t("home.password.submit") }}</el-button>
            </div>
          </div>
          <div class="card-body" v-else>
            <el-result icon="success" :title="$t('global.change.success')" :subTitle="$t('setting.account.relogintip')">
              <template slot="extra">
                <el-button type="primary" size="medium">
                  <a href="/logout" style="color:#fff">
                    {{ $t("global.button.ok") }}
                  </a>
                </el-button>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>